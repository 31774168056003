export default {
    // FIXME: this is causing issues as we're trying to match the cookie against
    // name using indexOf which will give a -1 for any substring included. This is probably
    // the cause of https://sailthru.atlassian.net/browse/CHA-187
    getCookie(name) {
        const cookies = document.cookie.split(';');
        let cookieMatch = cookies.filter((cookie) => cookie.trim().indexOf(`${name}=`) === 0);
        cookieMatch = cookieMatch.length > 0 ? cookieMatch[0].split(`${name}=`)[1] : '';
        return (cookieMatch && cookieMatch.trim()) || '';
    },
};
