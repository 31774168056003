export default {
    fetchWithTimeout: ( url, fetchInit, timeout ) => {
        if (typeof AbortController == 'undefined' || !timeout) {
            return fetch(url, fetchInit);
        }
        const abortController = new AbortController();
        const expandedFetchInit = {
            ...fetchInit,
            signal: abortController.signal,
        };
        return new Promise( (resolve, reject) => {
            // Set timeout timer
            let timer = setTimeout(
                () => {
                    abortController.abort();
                    reject( new Error('Request timed out') );
                },
                timeout,
            );

            fetch( url, expandedFetchInit )
                .then(
                    response => {
                        clearTimeout(timer);
                        resolve( response );
                    },
                    err => {
                        clearTimeout(timer);
                        reject( err );
                    },
                );
        })
    },
}
