import config from './modules/config';
import spm from './modules/spm';
import external from './modules/external';

const { debug, init, personalize, track, integration, overlay } = spm(config);

const getCookie = external.getCookie;

export {
    getCookie,
    debug,
    init,
    personalize,
    track,
    integration,
    overlay,
};
