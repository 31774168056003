import cookies from './cookies';
import {
    SESS_OVERLAY_PARAMS_COOKIE_NAME,
    OVERLAY_PARAM_PREVIEW,
} from './constants';

// NOTE: these variables are shared globally, the values persist and are shared between different
// invocations of defaultFn
let urlFragmentParams;
let urlQueryParams = {};
let urlQueryParamsNeedParsing = true;

// adapted from https://github.com/unshiftio/querystringify
function decode(input) {
    try {
        return decodeURIComponent(input.replace(/\+/g, ' '));
    } catch (e) {
        return null;
    }
}

// adapted from https://github.com/unshiftio/querystringify
function querystring(query) {
    const parser = /([^=?&]+)=?([^&]*)/g;
    const result = {};
    let part;
    let key;
    let value;

    while (part = parser.exec(query)) {
        key = decode(part[1]);
        value = decode(part[2]);

        if (key === null || value === null || key in result) continue;
        result[key] = value;
    }

    return result;
}

function pushUserIdKeyAndUserIdValue(config, params) {
    if (typeof config.userIdKey === 'undefined' || config.userIdKey === 'hid') {
        const horizonId = cookies.read('sailthru_hid');
        if (horizonId) {
            params.push('userIdKey=hid');
            params.push(`userIdValue=${horizonId}`);
        }
    } else if (config.userIdValue) {
        params.push(`userIdKey=${config.userIdKey}`);
        params.push(`userIdValue=${config.userIdValue}`);
    }
    return params;
}

function urlHashToMap() {
    const hash = window.location.hash.substr(1);
    urlFragmentParams = hash.split('&')
        .map(el => el.split('='))
        .reduce((keyValue, splittedData) => { keyValue[splittedData[0]] = decodeURIComponent(splittedData[1]); return keyValue; }, {});
    return urlFragmentParams;
}

function isPreview() {
    return urlFragmentParams[OVERLAY_PARAM_PREVIEW] !== undefined;
}

function getPreviewIdFromAnchor() {
    if (isPreview()) {
        return urlFragmentParams[OVERLAY_PARAM_PREVIEW];
    }
    return undefined;
}

function pushDeviceTypeInfo(isMobile, params) {
    if (isMobile !== undefined) {
        const isMobileParamVal = isMobile ? '1' : '0';
        params.push(`isMobile=${isMobileParamVal}`);
    }
    return params;
}

function getSessionOverlayUrlQuery() {
    const sessOverlayParamsCookie = cookies.read(SESS_OVERLAY_PARAMS_COOKIE_NAME);
    if (sessOverlayParamsCookie) {
        return decodeURIComponent(sessOverlayParamsCookie);
    }
    return window.location.search;
}

function pushLandingPageParams(params) {
    const urlQuery = getSessionOverlayUrlQuery();
    if (urlQuery && urlQuery.length > 1 && urlQuery[0] === '?') {
        if (typeof URL === 'function') {
            const tempUrl = new URL(`http://domain.tld${urlQuery}`);
            const searchParams = tempUrl.searchParams;
            for (const paramName of searchParams.keys()) {
                params.push(`page=${encodeURIComponent(`${paramName}=${searchParams.get(paramName)}`)}`);
            }
        } else {
            const searchParams = querystring(urlQuery);
            for (const paramName of Object.keys(searchParams)) {
                params.push(`page=${encodeURIComponent(`${paramName}=${searchParams[paramName]}`)}`);
            }
        }
    }
    return params;
}

function urlVars() {
    let tokens;
    const query = window.location.search;
    const regex = /[?&]+([^=&]+)=([^&]*)[^&#]*/gi;

    while (tokens = regex.exec(query)) {
        urlQueryParams[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    urlQueryParamsNeedParsing = false;
    return urlQueryParams;
}

function getUrlParam(parameter) {
    if (urlQueryParamsNeedParsing) {
        urlVars();
    }
    const urlparameter = urlQueryParams[parameter];
    return urlparameter;
}

function reset() {
    urlQueryParamsNeedParsing = true;
    urlQueryParams = {};
}

const defaultFn = () => {
    urlHashToMap();
    urlVars();
    return {
        pushUserIdKeyAndUserIdValue,
        urlHashToMap,
        getPreviewIdFromAnchor,
        isPreview,
        urlFragmentParams,
        pushDeviceTypeInfo,
        getUrlParam,
        pushLandingPageParams,
        reset,
    };
};

export default defaultFn;
